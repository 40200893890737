export default class Header {
  constructor() {
    this.timerId = null
    this.DOM = {}

    this.init()
  }

  init() {
    this.cacheSelectors()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$body = $('body')
    this.DOM.$header = $('.header')
    this.DOM.$headerOverlay = $('.header-overlay')
    this.DOM.$phoneLink = $('.js-headerPhone')
    this.DOM.$phoneList = $('.panel-phone__list')
  }

  bindEvents() {
    const self = this
    const ww = $(window).width()

    $(window).on('load scroll', () => this.headerOnScroll())

    this.DOM.$phoneLink
      .on('click', (e) => {
        if (ww < 1199) {
          e.preventDefault()
          self.phoneToggle()
        }
      })
      .on('mouseenter', () => {
        if (ww > 1200) {
          self.phoneMouseenter()
        }
      })
      .on('mouseleave', () => {
        this.timerId = setTimeout(() => {
          self.phoneMouseleave()
        }, 300)
      })
    this.DOM.$phoneList
      .on('mouseenter', () => {
        clearTimeout(this.timerId)
        self.phoneMouseenter()
      })
      .on('mouseleave', () => {
        this.timerId = setTimeout(() => {
          self.phoneMouseleave()
        }, 300)
      })
  }

  headerOnScroll() {
    const scrolled = $(window).scrollTop()

    if (scrolled > 0) {
      this.DOM.$header.addClass('is_sticky')
      this.DOM.$headerOverlay.addClass('is_sticky')
    } else {
      this.DOM.$header.removeClass('is_sticky')
      this.DOM.$headerOverlay.removeClass('is_sticky')
    }
  }

  phoneToggle() {
    this.DOM.$body.toggleClass('is-phone-open')
  }

  phoneMouseenter() {
    this.DOM.$body.addClass('is-phone-open')
  }

  phoneMouseleave() {
    this.DOM.$body.removeClass('is-phone-open')
  }
}
