export default class Menu {
  constructor() {
    this.DOM = {}
    this.burgerClicked = false
    this.init()
  }

  init() {
    this.cacheSelectors()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$body = $('body')
    this.DOM.$burger = $('.js-menuBurger')
    this.DOM.$header = $('.header')
    this.DOM.$menu = $('.menu')
    this.DOM.$menuLink = $('.menu-nav__link')
    this.DOM.$menuServices = $('.menu-services')
    this.DOM.$servicesLink = $('.js-menuServicesLink')
    this.DOM.$servicesSection = $('[data-section="services"]')
  }

  bindEvents() {
    this.DOM.$burger.on('click', () => this.menuToggle())
    this.DOM.$servicesLink.on('click', (e) => this.menuServicesToggle(e))
    this.DOM.$menuLink.on('click', (e) => {
      const target = $(e.currentTarget)

      if (target.hasClass('js-scrollTo')
        && this.DOM.$body.hasClass('is-menu-open')) {
        this.menuToggle()
      }
    })
  }

  menuToggle() {
    const self = this

    if (self.burgerClicked === true) {
      return false
    }

    self.burgerClicked = true

    if (self.DOM.$body.hasClass('is-menu-open')) {
      self.DOM.$burger.removeClass('is_opened')
      self.DOM.$body.removeClass('is-menu-open')
      setTimeout(() => {
        self.DOM.$menu.removeClass('menu_visible')
        self.DOM.$menuServices.addClass('menu-services_visible')
      }, 300)
    } else {
      self.DOM.$menu.addClass('menu_visible')
      setTimeout(() => {
        self.DOM.$burger.addClass('is_opened')
        self.DOM.$body.addClass('is-menu-open')
      }, 33)
    }

    if (self.DOM.$body.hasClass('is-menu-services-open')) {
      self.DOM.$body.removeClass('is-menu-services-open')
    }

    setTimeout(() => {
      self.burgerClicked = false
    }, 500)
  }

  menuServicesToggle(e) {
    const self = this
    const ww = $(window).width()
    const headerHeight = self.DOM.$header.height()

    if (ww > 1280) {
      e.preventDefault()

      self.DOM.$menuServices.addClass('menu-services_visible')
      setTimeout(() => {
        self.DOM.$body.addClass('is-menu-services-open')
      }, 33)
    } else if (self.DOM.$servicesSection.length > 0) {
      const offsetTop = self.DOM.$servicesSection.offset().top

      e.preventDefault()

      this.menuToggle()

      $('html, body').animate({
        scrollTop: offsetTop - headerHeight,
      })
    }
  }
}
