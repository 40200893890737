import Header from './Header'
import Menu from './Menu'
import Modal from './Modal'
import Services from './Services'
import './scroll-to'
import './scroll-down'
import './video-modal'
import './preloader'

export default class App {
  constructor() {
    this.init()
  }

  init() {
    this.createComponents()
  }

  createComponents() {
    this.initHeader()
    this.initMenu()
    this.initModal()
    this.initServices()
  }

  initHeader() {
    new Header()
  }

  initMenu() {
    new Menu()
  }

  initModal() {
    new Modal()
  }

  initServices() {
    if ($('.js-serviceCase').length > 0) {
      new Services()
    }
  }
}
