const { hash } = window.location
const scrollLink = $(`.js-scrollTo[href="/${hash}"]`)

$('.js-scrollTo').on('click', (event) => {
  const self = $(event.currentTarget)
  const scrollId = self.attr('href').replace('/#', '')
  const headerHeight = $('.header').outerHeight() || 0
  const containerScroll = $(`[data-scroll-section=${scrollId}]`)
  const offset = containerScroll.data('offset') || 0

  event.preventDefault()

  if (containerScroll.length === 0) return

  const containerOffset = containerScroll.offset().top
  const scrollOffset = containerOffset - headerHeight - offset

  $('body, html').animate({ scrollTop: scrollOffset })
})

$(window).on('load', () => {
  if (scrollLink.length > 0) {
    scrollLink.trigger('click')
  }
})
