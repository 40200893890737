import { aosInit } from './aos'
import LogoAnimation from './LogoAnimation'

const body = $('body')
const preloader = $('.js-preloader')
const preloaderPercentage = $('.js-preloaderPercentage')
const perfData = window.performance.timing
const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart)
const time = ((EstimatedTime / 1000) % 60) * 100
const start = 0
const end = 100
const duration = time
const width = 100

$('.js-preloaderBar').animate({
  width: `${width}%`,
}, time)

animateValue()

function animateValue() {
  const range = end - start
  const stepTime = Math.abs(Math.floor(duration / range))
  let current = start
  const increment = end > start ? 1 : -1

  const timer = setInterval(() => {
    current += increment
    preloaderPercentage.text(current)
    if (current === end) {
      clearInterval(timer)
    }
  }, stepTime)
}

setTimeout(() => { preloader.addClass('is_preloader_hide') }, time)
setTimeout(() => { preloader.addClass('is_blind_hide') }, time + 800)
setTimeout(() => {
  aosInit()
  body.addClass('is-animate-show')
  preloader.hide()
}, time + 1500)
setTimeout(() => {
  new LogoAnimation()
}, time + 2500)
