export default class LogoAnimation {
  constructor() {
    this.DOM = {}

    this.fps = 22
    this.isMobile = $(window).width() < 768
    this.logoShift = 0
    this.shift = this.isMobile ? 1200 : 1920

    this.init()
  }

  init() {
    this.cacheSelectors()
    this.logoAnimStep()
  }

  cacheSelectors() {
    this.DOM.$logo = $('.js-logoAnimation')
  }

  logoAnimStep() {
    const self = this

    self.DOM.$logo.css('opacity', '1')

    setTimeout(() => {
      self.logoShift += self.isMobile ? 40 : 64
      if (self.logoShift >= self.shift) {
        return
      }
      self.DOM.$logo.css('background-position', `-${self.logoShift}px 0`)
      self.logoAnimStep()
    }, self.fps)
  }
}
