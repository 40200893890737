export default class Services {
  constructor() {
    this.DOM = {}
    this.ww = $(window).width()

    this.init()
  }

  init() {
    this.cacheSelectors()
    this.positionCase()
    this.wrapperSize()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$serviceCase = $('.js-serviceCase')
    this.DOM.$serviceCaseLast = $('.js-serviceCase:last')
    this.DOM.$serviceBody = $('.services-body')
    this.DOM.$modalBox = $('#modalServiceCase .modal-box')
  }

  bindEvents() {
    $(window).on('load scroll', () => this.scrollHandler())
    this.DOM.$serviceCase.on('click', (e) => this.caseHandler(e))
  }

  wrapperSize() {
    const heightOffset = this.ww < 1280
      ? (this.ww < 768 ? 200 : 350)
      : 100
    const detail = this.DOM.$serviceCaseLast.find('> div')
    const height = detail.height() - heightOffset
    const offsetTop = detail.offset().top

    this.DOM.$serviceBody.css({
      height: `${height + offsetTop}px`,
    })
  }

  positionCase() {
    const count = this.DOM.$serviceCase.length - 1

    this.DOM.$serviceCase.each((index, el) => {
      const h = this.ww < 1280
        ? 200
        : 250
      const translateY = h * index
      const scale = 1 + (0.15 * index)

      $(el).attr({
        'data-last': count === index,
        'data-translateY': translateY,
        'data-scale': scale,
      })
      $(el).css({
        zIndex: index + 1,
        transform: `
          translateY(${translateY}px) scale(${scale})
        `,
      })
    })
  }

  scrollHandler() {
    const number = this.ww < 1280
      ? 0.0007
      : 0.0005
    const scrolled = $(window).scrollTop()
    const value = scrolled * number

    this.DOM.$serviceCase.each((index, el) => {
      const translateY = $(el).data('translatey')
      const last = $(el).data('last')
      let scale = $(el).data('scale') - value

      if (last && scale <= 1) {
        scale = 1
      } else if (scale <= 0.3) {
        scale = 0.3
      }

      $(el).css({
        transform: `
          translateY(${translateY}px) scale(${scale})
        `,
      })
    })
  }

  caseHandler(e) {
    const current = $(e.currentTarget)
    const detail = current.find('.js-serviceCaseInner').clone()

    this.DOM.$modalBox.html(detail)
  }
}
